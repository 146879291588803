import {createAsyncThunk } from '@reduxjs/toolkit';
import { serviceUrl } from '../app/shared/serviceUrl';
import ContactForm from './ContactForm';

export const postMessage = createAsyncThunk(
    'contactService',
    async (message = ContactForm, {dispatch}) => {
        console.log("in post message- entered", message);
        const response = await fetch(serviceUrl+'contactService', 
           {
             method : 'POST',
             body : JSON.stringify(message),
             headers : { 'Content-Type': 'application/json'},
             mode: "cors" ,
             accept: "*/*"
           });
        console.log("in post message - fetch completed");
        if (!response.ok) {
            return Promise.reject('Unable to send message, status: ' + response.status);
        }
        console.log("waiting for response");
        const data = await response.json(); 
        console.log("in post message, data received=", data);
        return data.response;
    }
)

export const recaptchaAuth = createAsyncThunk(
    'recaptchaService',
    async (message, {dispatch}) => {
        console.log("in post message recaptcha service- entered=", message);
        const response = await fetch(serviceUrl+'recaptchaService', 
           {
             method : 'POST',
             body : JSON.stringify(message),
             headers : { 'Content-Type': 'application/json'},
             mode: "cors" ,
             accept: "*/*"
           });
        console.log("in post message - fetch completed");
        if (!response.ok) {
            return Promise.reject('Unable to send message, status: ' + response.status);
        }
        console.log("waiting for response");
        const data = response.json();
        console.log("in post message, data received=", data);
        
    }
)