import { Container, Row, Col } from 'reactstrap';
import Agent from '../app/assets/images/tony-pic.JPG';

const NameWithImage = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col lg="4">
          <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">
            <img src={Agent} alt="anthony madaffari" aria-label= "anthony madaffari" width={80}/>
            </a>
          </Col>
          <Col lg="8">
            <p>
              ANTHONY MADAFFARI<br/>
              BHHS Nevada Properties<br/>
              <a
                  role='button'
                  href='tel:+17023261282'
                  style={{color: '#a7a9ac'}}
                >
                  (702) 326-1282
                </a><br/>
                S.0175659
            </p>
          </Col>
        </Row>
      </Container>          
    </div>
  );
};
  
  export default NameWithImage;