import {Button, ButtonGroup, Container, Row, Col} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeEmbed from "./YoutubeEmbed";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

const HeroContent = () => {
    return(
            <Container className="row-content" style={{background: '#ECE3E5' }}>
                <Row className="row-content" style={{marginTop: '3%'}}>
                    <h1 className='text-center' style={{font: 'Diphylleia', fontSize: '1em'}}> <b>Welcome to Las Vegas Home Buyers Market!</b> If you're looking to <b>buy a house in Las Vegas,</b> you've come to the right place. Our mission is to guide you through the <b>Las Vegas real estate market</b> and help you navigate the various options available. Whether you're interested in <b>affordable homes in Las Vegas, new homes,</b> or <b>Las Vegas investment properties,</b> our expert team is here to assist you every step of the way.<br /><br/>With access to the latest <b>Las Vegas MLS listings</b>, we ensure you have all the information you need to make informed decisions. We are dedicated to helping you find the perfect property, whether it's a <b>townhome</b> or a <b>fixer-upper</b> in one of the many <b>Las Vegas gated communities</b>.<br /><br/>At Las Vegas Home Buyers Market, we pride ourselves on providing personalized services to ensure your home buying experience is seamless and stress-free. Get started today and discover the best options for <b>Las Vegas houses for sale!</b><br /><br/>For more in-depth analysis of the local market, visit <Link to= 'https://lasvegashousingmarketreport.com/' style={{ color: '#670038', fontWeight: 'bold' }}> Las Vegas Housing Market Report</Link>.</h1>
                    <Col xxl='8' xl='8' lg='6' md='6'sm='6'>
                        <Row>
                            <h2  className='text-center' style={{font: 'Diphylleia', color: '#552448', fontSize: '1.5em', marginTop: '1%'}}>Las Vegas Home Buyers</h2>
                        </Row>
                        <Row>
                            <YoutubeEmbed embedId="ZfzEsiLL_NQ" />
                        </Row>
                            <div className="row-content text-center">
                                <ButtonGroup>
                                    <a href="https://anthonymadaffari.bhhsnv.com/" target="_blank" rel="noopener noreferrer">
                                        <Button className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                            Sellers
                                        </Button>
                                    </a> 
                                    <a href="https://bhhsnv.findbuyers.com/tonym@bhhsnv.com" target="_blank" rel="noopener noreferrer">
                                        <Button style={{marginLeft: '1rem', fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                                            Home Value
                                        </Button>
                                    </a>
                                </ButtonGroup>
                            </div>
                    </Col>
                    <Col>
                        <Row>
                            <h2 className= "text-center" style={{font: 'Diphylleia', color: '#552448', fontSize: '1.5em', marginTop: '2%'}} >Future Home Buyer</h2>
                            <hr/>
                        </Row>
                        <Row>
                            <p className="errorMessage" style={{fontStyle: "italic"}}>* Indicates required field</p>
                        </Row>
                        <Row>
                            <ContactForm/>
                        </Row>
                    </Col>
                </Row>
            </Container> 
                                   
            
         
    );
};


export default HeroContent;