import { useState } from "react";
import { Button,Col,FormGroup,Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { Formik, Field, Form,ErrorMessage } from "formik";
import { validateContactForm } from "../utils/validateContactForm";
import { postMessage, recaptchaAuth } from "./contactSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const ContactForm = () => {

    const[ qualifiedLender, setQualifiedLender] = useState("");
    const [ doNotCallList, setDoNotCallList] = useState("");
  
    const handleRadioChange = (value) => { 
        setQualifiedLender(value); 
    };

    const handleRadioChange2 = (value) => { 
        setDoNotCallList(value); 
    };

    const dispatch = useDispatch();
    const recaptchaRef = useRef(null);
    //const recaptchaRef = React.createRef();

    const notifySuccess = () => {
        toast("Message sent successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    const notifyError = () => {
        toast("Something went wrong!", {
            position: toast.POSITION.BOTTOM_CENTER,
            className: 'toast-message'
        });
    };
    toast.configure();

    const getRecaptchaScore = () => {
        const inputVal = ContactForm.email;
        const token = recaptchaRef.current.getValue();
        console.log("token = "+token);
        dispatch(recaptchaAuth({inputVal: inputVal, token: token}));
    };

    const handleSubmit = (values,{resetForm}) => {
        console.log('form values:', values);
        console.log('in JSON format:', JSON.stringify(values));

        const message = {
            name: values.name,
            email: values.email,
            phoneNum: values.phoneNum,
            move: values.move,
            buy : values.buy, 
            doNotCallList : doNotCallList,
            criteria : values.criteria,
            qualifiedLender : qualifiedLender,
            type: "REAL_ESTATE"
        }

        dispatch(getRecaptchaScore);
        dispatch(postMessage(message));
        resetForm();
        handleRadioChange('');
        handleRadioChange2('');
        recaptchaRef.current.reset();     
    };

    return(
        <Formik
        initialValues={{
            name: '',
            email: '',
            phoneNum: '',
            move: '',
            buy: '', 
            doNotCallList: '',
            criteria: '',  
            qualifiedLender: '', 
        }}
        onSubmit ={handleSubmit}
        validate ={validateContactForm}
        >
            <Form style={{fontFamily: 'Diphylleia'}}>
                <FormGroup row>
                    <Label className="required" htmlFor="name">Name </Label>
                    <Col>
                        <Field 
                            name ='name' 
                            className ='form-control'/>
                        <ErrorMessage name = 'name'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                   <Label className="required" htmlFor="email">Email</Label>
                    <Col>
                        <Field 
                            isrequired = 'true'
                            name ='email'
                            type ='email'
                            className ='form-control'/>
                        <ErrorMessage name = 'email'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>  
                    </Col> 
                </FormGroup>
                <FormGroup row>
                <Label className="required" htmlFor="phoneNum">Phone</Label>
                    <Col>
                        <Field 
                            isrequired = 'true'
                            name ='phoneNum'
                            className ='form-control'/>
                        <ErrorMessage name = 'phoneNum'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className="required" htmlFor="move">How long before you need to move?</Label>
                    <Col>
                        <Field 
                            isrequired = 'true'
                            name ='move'
                            className ='form-control'/>
                        <ErrorMessage name = 'move'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row> 
                <Label className="required" htmlFor="buy">Do you need to sell a home to buy a new home?</Label>
                    <Col> 
                        <Field 
                            isrequired = 'true'
                            name ='buy'
                            className ='form-control'/>
                        <ErrorMessage name = 'buy'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{size:12}} className="text-black required" >
                        By filling this out, will you allow us to call you if you are on the Do Not Call list?  {' '}{' '}{' '}
                        <Field
                            name='doNotCallList'
                            type ='radio'
                            value = 'Yes'
                            checked={doNotCallList === "Yes"}
                            onChange={() => handleRadioChange2( "Yes")}
                            className ='form-radio-input'
                            /> {' '}
                        Yes {' '}
                        <Field
                            name='doNotCallList'
                            type ='radio'
                            value = 'No'
                            checked={doNotCallList === "No"}
                            onChange={() => handleRadioChange2( "No")}
                            className ='form-radio-input'
                            /> {' '}
                        No    
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className="required" htmlFor="criteria">What is your criteria for a new home ? Ex. Bedrooms, SqFt, Location etc.</Label>
                    <Col>
                        <Field
                            isrequired = 'true'
                            name ='criteria'
                            as= 'textarea'
                            rows='5'
                            className ='form-control'/>
                        <ErrorMessage name = 'criteria'>
                            {(msg) => <p className="errorMessage">{msg}</p>}
                        </ErrorMessage>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={{size:12}} className="text-black required" >
                    Are you qualified with a lender? {' '}{' '}
                        <Field
                        name='qualifiedLender'
                        type ='radio'
                        value = 'Yes'
                        checked={qualifiedLender === "Yes"}
                        onChange={() => handleRadioChange( "Yes")}
                        className ='form-radio-input'
                        /> {' '}
                    Yes {' '}
                    <Field
                        name='qualifiedLender'
                        type ='radio'
                        value = 'No'
                        checked={qualifiedLender === "No"}
                        onChange={() => handleRadioChange( "No")}
                        className ='form-radio-input'
                        /> {' '}
                    No
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md ={{size:5}}>
                        <ReCAPTCHA 
                            sitekey={process.env.REACT_APP_SITE_KEY} 
                            ref={recaptchaRef}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col  className= "text-center" md ={{size:10, offset:1}}>
                        <Button  onClick={Object.values(validateContactForm).length === 0 ? notifySuccess : notifyError} type= 'submit' className="shadow-5-strong"style={{fontFamily: 'Diphylleia', fontSize: 'small', color: '#a7a9ac',background: '#552448', boxShadow: '1px 2px 9px #000001'}}>
                            Submit
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </Formik>
    );
};

export default ContactForm;