export const validateContactForm = (values) => {
    const errors = {};
    
    if(!values.name) {
        errors.name = 'Required';
    }else if(values.name.length < 2) {
        errors.name = 'Must be at least 2 characters.';
    } else if(values.name.length > 40){
        errors.name = 'Must be 40 characters or less.';
    }

    if(!values.email.includes('@')) {
        errors.email = 'Please use a valid email address.';
    } 

    if(!values.buy) {
        errors.buy = 'Required';
    }else if(values.buy.length < 2) {
        errors.buy = 'Must be at least 2 characters.';
    } else if(values.buy.length > 30){
        errors.buy = 'Must be 30 characters or less.';
    }

    if(!values.move) {
        errors.move = 'Required';
    }else if(values.move.length < 3) {
        errors.move = 'Must be at least 3 characters.';
    } else if(values.move.length > 30){
        errors.move = 'Must be 30 characters or less.';
    }

    if(!values.criteria) {
        errors.criteria = 'Required';
    }else if(values.criteria.length < 3) {
        errors.criteria = 'Must be at least 3 characters.';
    } else if(values.criteria.length > 40){
        errors.criteria = 'Must be 40 characters or less.';
    }

    const reg = /^\d+$/;
    if(!reg.test(values.phoneNum)) {
        errors.phoneNum = 'The phone number should contain only numbers.';
    }
  
    console.log(Object.values(validateContactForm).length);
    return errors;
};